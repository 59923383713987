.active{
    border-bottom: 2px solid #F2DF93; 
    border-radius: 5px;  
    color: #F2DF93; 
    /* background-color: #d9d9d975; */
}


.nav_link {
    display: block !important;
}
.nav_link :hover + .arrow-icons{
    display: block !important;
}



