*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.slick-next{
    right: 30px !important;
}

.slick-prev {
    left: 30px !important;
    z-index: 10;
}

.slick-prev:before, .slick-next:before{
    font-size: 30px !important;

}