.features {
  text-transform: capitalize !important;
}



.homeItem {
  height: 310px !important;
  border-radius: 0 0 50% 0/0 0 100% 0 !important;
  overflow: hidden;
  border-right-width: 1px !important;
  border-bottom-width: 10px;
  border-color: rgb(255, 136, 0);
  position: relative;
  background-position: center;
  /* background-color: #1f1e1ecb; */
}

.homeItem img {
  height: 350px !important;
  width: 100% !important;
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

.map {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
    rgba(0, 0, 0, 0.05) 0px 5px 10px !important;
}
/* .see_btn{
  position: relative;
  width: 100%;
}


.see_btn:hover > span{
  content:"->";
  position: absolute;
  right: 0;
  color: red;
  transition: 0.4 all ease-in-out;
  animation-duration:;
  
} */

.buttonColor{
  background-color: red;
}

@media screen and (max-width: 1076px) {
  .homeItem {
    width: 100% !important;
    height: 100% !important;
    border-right-width: 0 !important;
    border-bottom-width: 0;
    border-radius: 0 !important;
  }
  .homeItem img {
    width: 100% !important;
    height: 100% !important;
    position: sticky;
  }
}
